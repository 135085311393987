import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { Twitter, Facebook, Mail } from "react-social-sharing";
import { Helmet } from "react-helmet";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

import { mobileS, mobileM, laptop } from "../components/breakpoints";
import RelatedPosts from "../components/RelatedPosts";

import Layout from "../components/layout";

deckDeckGoHighlightElement();

const PostContainer = styled.article`
    max-width: 700px;
    padding: 10px 0px;
    margin-left: auto;
    margin-right: auto;
    ul {
        list-style: none;
    }
    li::before {
        content: "👉 ";
    }
    blockquote {
        margin-bottom: 10px;
    }
    @media ${mobileS} {
        p,
        li {
            font-size: 1em;
            line-height: calc(1em * 1.5);
        }
    }
    @media ${laptop} {
        p,
        li {
            font-size: 1.25em;
            line-height: calc(1.25em * 1.5);
        }
    }
`;

const PostHeadline = styled.h1`
    font-size: 2.5em;
    margin: 0;
    @media ${mobileM} {
        font-size: 2.5em;
    }
    @media ${laptop} {
        font-size: 3.25em;
    }
`;

const Category = styled.div`
    color: #c33dd4;
`;

export default function Template({
    data: {
        site,
        markdownRemark: {
            frontmatter: { title, date, category, featuredImage, tags, path },
            html,
        },
    },
}) {
    let featuredImgFluid = featuredImage.childImageSharp.fluid;
    let ogImage =
        site.siteMetadata.siteUrl + featuredImage.childImageSharp.fixed.src;
    return (
        <Layout>
            <Helmet
                title={title}
                meta={[
                    {
                        name: "og:title",
                        content: title,
                    },
                    {
                        name: "og:image",
                        content: ogImage,
                    },
                    {
                        name: "og:type",
                        content: "article",
                    },
                    {
                        name: "og:type",
                        content: "article",
                    },
                    {
                        name: `description`,
                        content: site.siteMetadata.description,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata.author,
                    },
                    {
                        name: `twitter:image`,
                        content: ogImage,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: site.siteMetadata.description,
                    },
                ]}
            />
            <PostContainer>
                <div className="blog-post">
                    <Category>{category}</Category>
                    <PostHeadline>{title}</PostHeadline>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <h2>{date}</h2>
                        <div>
                            <Twitter
                                solidcircle
                                small
                                style={{
                                    margin: 0,
                                }}
                                link={"https://unicorn.computer" + path}
                                message={title}
                                label="make twete"
                            />
                            <Facebook
                                solidcircle
                                small
                                style={{
                                    margin: 0,
                                }}
                                link={"https://unicorn.computer" + path}
                                message={title}
                                label="make twete"
                            />
                            <Mail
                                solidcircle
                                small
                                style={{
                                    margin: 0,
                                }}
                                link={"https://unicorn.computer" + path}
                                message={title}
                                label="make twete"
                            />
                        </div>
                    </div>
                    <Img
                        sizes={{
                            ...featuredImgFluid,
                            aspectRatio: 4 / 3,
                        }}
                    />
                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    <RelatedPosts
                        category={category}
                        tags={tags}
                        currentArticleSlug={path}
                    />
                </div>
            </PostContainer>
        </Layout>
    );
}

export const pageQuery = graphql`
    query ($path: String!) {
        site {
            siteMetadata {
                title
                description
                author
                siteUrl
            }
        }
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MM/DD/YYYY")
                path
                title
                category
                tags
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                        fixed {
                            src
                        }
                    }
                }
            }
        }
    }
`;
