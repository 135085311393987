import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

import { SimilarArticlesFactory } from "../data/SimilarArticlesFactory";
import Tags from "./tag";

export const SimilarPostSecion = styled.section`
    padding: 10px 0;
`;

export const MoreHeader = styled.h3`
    border-bottom: 1px solid black;
    max-width: 50%;
`;

export const SimilarPost = styled.article`
    h4 {
        line-height: 24px;
        font-size: 16px;
        color: #c33dd4;
    }
    a {
        text-decoration: none;
    }
`;

const RelatedEmptyState = styled.div`
    text-align: center;
    padding: 15px;
    background-color: #cbd0d5;
    border-radius: 5px;
    h4 {
        font-size: 16px;
        color: grey;
    }
`;

const SimilarArticlesComponent = ({ articles, tags }) => (
    <SimilarPostSecion>
        <Tags tags={tags} />
        <MoreHeader>
            The Algorithm
            <span role="img" aria-label="trademark symbol">
                ™️
            </span>{" "}
            Recommends
        </MoreHeader>
        {articles.length ? (
            articles.map(({ article: { title, path, category } }, i) => (
                <SimilarPost key={i}>
                    <Link to={path}>
                        <h4>{title}</h4>
                    </Link>
                </SimilarPost>
            ))
        ) : (
            <RelatedEmptyState>
                <h4>
                    <span role="img" aria-label="robot face">
                        🤖
                    </span>{" "}
                    I got nothing{" "}
                    <span role="img" aria-label="robot face">
                        🤖
                    </span>
                </h4>
            </RelatedEmptyState>
        )}
    </SimilarPostSecion>
);

// (1.) Query for articles
export default props => (
    <StaticQuery
        query={graphql`
            query SimilarArticles {
                posts: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {}
                    limit: 1000
                ) {
                    edges {
                        node {
                            frontmatter {
                                title
                                date
                                tags
                                path
                                category
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const { category, tags, currentArticleSlug } = props;
            const articles = data.posts.edges.map(
                ({
                    node: {
                        id,
                        frontmatter: { title, path, tags, category }
                    }
                }) => ({
                    id,
                    title,
                    path,
                    category,
                    tags
                })
            );
            const similarArticles = new SimilarArticlesFactory(
                articles,
                currentArticleSlug
            )
                .setMaxArticles(4)
                .setCategory(category)
                .setTags(tags)
                .getArticles();
            return (
                <SimilarArticlesComponent
                    articles={similarArticles}
                    tags={tags}
                />
            );
        }}
    />
);
